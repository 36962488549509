import React, {useEffect} from 'react';
import "./slider.css";

const HomeSlider = () => {
  // const [] = useState();


    // responsiveSlider();

  useEffect(() => {
    let counter = 1;
    setInterval(function(){
      document.getElementById('radio' + counter).checked = true;
      counter++;
      if(counter > 7){
        counter = 1;
      }
    }, 4000);
  }, [])

  return (
      <>
        <div className="slider">
          <div className="slides">

            <input type="radio" name="radio-btn" id="radio1" />
              <input type="radio" name="radio-btn" id="radio2" />
                <input type="radio" name="radio-btn" id="radio3" />
                  <input type="radio" name="radio-btn" id="radio4" />
                  <input type="radio" name="radio-btn" id="radio5" />
                  <input type="radio" name="radio-btn" id="radio6" />
                  <input type="radio" name="radio-btn" id="radio7" />

                    <div className="slide first">
                      <img src="/img/ims-images/slider-images/EMS-Sign-Up.png" alt="" />
                    </div>
                    <div className="slide">
                        <img src="/img/ims-images/slider-images/ems-pc.png" alt="" />
                    </div>
                    <div className="slide">
                        <img src="/img/ims-images/222.png" alt="" />
                    </div>
                    <div className="slide">
                      <img src="/img/ims-images/vvR2.png" alt="" />
                    </div>
                    <div className="slide">
                      <img src="/img/ims-images/vvR1.png" alt="" />
                    </div>
                    <div className="slide">
                      <img src="/img/ims-images/tiptopLap.png" alt="" />
                    </div>
                    <div className="slide">
                      <img src="/img/ipms-images/ipms-lap.png" alt="" />
                    </div>

                    <div className="navigation-auto">
                      <div className="auto-btn1" />
                      <div className="auto-btn2" />
                      <div className="auto-btn3" />
                      <div className="auto-btn4" />
                      <div className="auto-btn5" />
                      <div className="auto-btn6" />
                      <div className="auto-btn7" />
                    </div>
          </div>

          <div className="navigation-manual">
            <label htmlFor="radio1" className="manual-btn"/>
            <label htmlFor="radio2" className="manual-btn" />
            <label htmlFor="radio3" className="manual-btn" />
            <label htmlFor="radio4" className="manual-btn" />
            <label htmlFor="radio5" className="manual-btn" />
            <label htmlFor="radio6" className="manual-btn" />
            <label htmlFor="radio7" className="manual-btn" />
          </div>

        </div>
      </>
  );


};

export default HomeSlider;


import "./oga-app.css"

const OgaAppSubHeader = (()=>{



    return(


        <>
       <section id="home" className="bg-dark-gradient overflow-hidden homMarTop ">
         
          <div className="container position-relative tecMoreOGAMoved">
            <div className="row min-vh-100 align-items-center ">
              <div className="col-md-7 wow fadeInUp fdOgaSave" data-wow-duration="0.5s">
                <img className="sm-img-120 yepImagesIm" src="/img/ims-images/ogaBoizMain.png" title="" alt="" />
                <div className="pt-3 d-flex downloadBtn">

                  <a className="me-3 downloadLink" target="blank" href="https://play.google.com/store/apps/details?id=com.intellims.ogaapp&pcampaignid=web_share"><img src="/img/effect/playstore.png" title="" alt="" /></a>
                  <a href="https://apps.apple.com/ng/app/oga-app/id6472498562" target="blank"><img  src="/img/effect/apple-store.png" title="" alt="" /></a>
                </div>
              </div>
              <div className="col-md-5 pe-xl-10 wow fadeInUp" data-wow-duration="0.5s">
                <label className="setOgaappLabe-Top px-3 py-1 rounded-3 mb-3">Oga-App offers you the best</label>
                {/*<h2 className="display-4 mb-4"> (Welcome to IntellEMS)</h2>*/}
                <p className="lead">
                Staff use their mobile phones to clock-in and clock-out for time paid (time attendance system).
                Oga-App uses the staff mobile phone GPS/location to check if they clocked in on time and if they stayed 
                in their workplace vicinity for the duration they are working.
                </p>
                {/*<img src="/img/ims-images/welcome-job.png" title="" alt="" />*/}
                <p className="lead">
                Oga-App automatically generates payroll record based on the actual hours the staff was at work.
                 Build and update staff working schedules directly from Oga-App.
                  {/*We proffer new effective solutions of job opportunities, with the biggest companies in the world.
                  This is a product created to solve the problem of unemployment as it is a converging point for the employer and the job seeker*/}
                      {/* 
                    <NavLink className="btn btn-success bg-secondary mb-0 btn-xl text-nowrap pt-3" to="/create-account">
                      <i className="fab fa-ubuntu" /> Create Account
                    </NavLink> */}
                </p>

              </div>
            </div>
          </div>
        </section>  
        
        
        </>
    )
})

export default OgaAppSubHeader
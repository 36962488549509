import React from 'react';
import Header from './layouts/Header';
import MetaDataDecorator from '../utilities/MetaDataDecorator';
import EmsSubHeader from './EMS/Ems-SubHeader';
// import HowDoesItWork from './EMS/HowDoesItWork';
import EmsFeatures from './EMS/EmsFeatures';
// import TestFeatures from './EMS/TestFeatures';
import AboutEms from './EMS/AboutEms';
import Footer from './layouts/Footer';
import MarketingVideo from './EMS/MarketingVideo';
import Testimonies from './EMS/Testimonies';
import SummaryOfEms from './EMS/SummaryOfEMS';
import Faq from './Faqs';
// https://www.youtube.com/watch?v=b5jxQaJNzjY
const EmsApp = () => {
  return (
      <>
        <div className="wrapper">
           <Header headerStyle="navbar navbar-expand-lg"  cssStyle="nav-link text-dark" logoImage="/img/ims-images/IMS-Bluey.png"
                   logoImageDark="/img/ims-images/IMS-Bluey.png"  logoImageWhite="/img/ims-images/IMS-Bluey.png"
                   showProduct={false}/>
          <MetaDataDecorator description="Home" title="EMS | Home" />
          <main>
            <EmsSubHeader />
            {/*<SummaryOfEms />*/}
            <AboutEms />
            <EmsFeatures />
            <MarketingVideo  backgroundImage="/img/ims-images/Thumby.jpg"  videoUrl="" />
            <Testimonies />
            <Faq faqBg="faq-main-w" faqTitle="faq-title-w bg-primary-after after-50px mb-4" faqItem="faq-item-w"
                 faqQuestion="faq-question-w" faqAnswer="faq-answer-w" faqIcon="faq-answer-icon-w" bgColor='bg-primary'/>
          </main>
        </div>
        <Footer footerBg="bg-dark footer" logoImage="/img/ims-images/intelli-log.png" />
      </>
  );
};

export default EmsApp;

import "./oga-app.css"


const AboutOgaApp = (() => {



    return (
        <>

            <section className="section bg-gray-100">
                <div className="container">
                    <div className="row section-heading justify-content-center text-center wow fadeInUp"
                        data-wow-duration="0.5s" data-wow-delay="0.1s">
                        <div className="col-md-12">
                            <h3 className="h1 bg-primary-after after-50px">About OgaApp</h3>
                            <div className="lead"> Oga-App is a mobile app and web tool built to improve honesty at the workplace. to manage your staff working & paid time.
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between flex-row-reverse">
                        <div className="col-md-6 my-1 wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                            <label className="setOgaappLabe-Top px-3 py-1 text-white rounded-3 mb-3">Best Tools For Employers</label>

                            <ul className="list-type-03 mb-4 list-unstyled">
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />

                                    Clock-In and Clock-Out: Staff can use their mobile phones to clock in and out, recording their work hours
                                </li>
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />

                                    GPS Location Tracking: The app uses GPS
                                    to verify if staff are clocking in on time and staying within the workplace vicinity during work hours.
                                </li>
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />
                                    Automatic Payroll Generation: Oga-App generates
                                    payroll records automatically based on the actual hours worked by the staff
                                </li>

                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />

                                    Schedule Management: Managers can build and update staff working schedules directly from the app.
                                </li>
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />

                                    Attendance Monitoring: The app tracks attendance and ensures staff are present during their scheduled work hours
                                </li>
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />

                                    Real-Time Notifications: Staff and managers receive real-time notifications about clock-ins, clock-outs, and any discrepancies in attendance
                                </li>
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />

                                    Reporting and Analytics: Oga-App provides detailed reports and analytics on staff attendance, punctuality, and work hours.
                                </li>
                            </ul>
                            {/* <a href="/intellems-features" className="btn btn-primary">Learn More</a> */}
                            {/*<div className="pt-3 d-flex">


                  <NavLink className="btn btn-success bg-success-gradient-dark mb-0 btn-xl text-nowrap" to="/create-account">
                    <i className="fab fa-ubuntu mr-3" /> Create Account
                  </NavLink>
                </div>*/}

                            {/*<div className="createBtn">

                  <NavLink className="btn btn-success bg-success-gradient-dark mb-0 btn-xl text-nowrap" to="/create-account">
                    <i className="fab fa-ubuntu mr-3" /> Create Account
                  </NavLink>
                </div>*/}
                        </div>
                        <div className="col-md-6 my-1 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                            <img className="sm-img-120" src="/img/ims-images/ogaStret.png" title="" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="about-us" className="section bg-primary">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-7  fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                            <label className="setOgaappLabe-Top px-3 py-1 text-white rounded-3 mb-3">Let's Get You started</label>
                            <p className="lead text-white">
                                Oga-App is a mobile app and web tool built to improve honesty at the workplace. to manage your staff working
                                & paid time. Make your employees show up to work on time and stay at work for the time you are paying them.
                            </p>
                            <ul className="list-type-03 mb-4 list-unstyled">
                                <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" />

                                    Download and Install: Download the Oga-App from your mobile app store or access the web tool through your browser.
                                </li>
                                <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" />

                                    Create an Account: Sign up for an account by providing your business details and setting up your profile
                                </li>
                                <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" />

                                    Add Staff: Enter your employees' information into the app, including their roles, work schedules, and contact details.
                                </li>
                                <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" />

                                    Set Up GPS Tracking: Enable GPS tracking on your employees'
                                    mobile devices to monitor their clock-ins, clock-outs, and location during work hours.
                                </li>
                                <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" />


                                    Configure Payroll Settings: Customize the payroll settings to automatically generate payroll records based on the actual hours worked by your staff.
                                </li>
                            </ul>
                            {/* <a  className="btn btn-secondary" href="/intellems-features">
                  Learn More
                </a> */}


                        </div>
                        <div className="col-lg-5 wow fadeInRight gitFullSideDiv" data-wow-duration="0.5s" data-wow-delay="0.1s">
                            <img className="lg-img-120 gitFullSide"  src="/img/ims-images/OGAAPP-SMFRONT.png" title="" alt="" />
                            <div className="d-flex downloadBtn2">

                                <a className="me-3" target="blank" href="https://play.google.com/store/apps/details?id=com.intellims.secureme&pcampaignid=web_share"><img src="/img/effect/playstore.png" title="" alt="" /></a>
                                <a href="https://apps.apple.com/ng/app/secureme/id6474111054" target="blank"><img src="/img/effect/apple-store.png" title="" alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            

        </>
    )
})

export default AboutOgaApp
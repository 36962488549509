import MetaDataDecorator from "../utilities/MetaDataDecorator"
import EmsSubHeader from "./EMS/Ems-SubHeader"
import IPMSSubHeader from "./iPMS/iPMS-SubHeader"
import Footer from "./layouts/Footer"
import Header from "./layouts/Header"
import AboutOgaApp from "./Oga-app/aboutOgaApp"
import OgaAppSubHeader from "./Oga-app/oga-app-subHeader"


const OgaApp = (()=>{


    return(


        <>
       <div className="wrapper">
       <Header headerStyle="navbar navbar-expand-lg"  cssStyle="nav-link text-dark" logoImage="/img/ims-images/IMS-Bluey.png"
                   logoImageDark="/img/ims-images/IMS-Bluey.png"  logoImageWhite="/img/ims-images/IMS-Bluey.png"
                   showProduct={false} featureShow={true}/>
                   <MetaDataDecorator description="Home" title="ogaApp | Home" />
              <OgaAppSubHeader/>
              <AboutOgaApp/>
            <Footer footerBg="bg-dark footer" logoImage="/img/ims-images/intelli-log.png" /> 
        
        </div> 
        
        
        </>
    )

})

export default OgaApp



const AboutSecureMe = (() => {


    return (


        <>
            <section className="section bg-gray-100">
                <div className="container">
                    <div className="row section-heading justify-content-center text-center wow fadeInUp"
                        data-wow-duration="0.5s" data-wow-delay="0.1s">
                        <div className="col-md-12">
                            <h3 className="h1 bg-primary-after after-50px">About SecureMe</h3>
                            <div className="lead"> SecureMe was built to help keep us all safer by tracking and recording our movement
                                only when activated by the individual.
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between flex-row-reverse">
                        <div className="col-md-6 my-1 wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                            <label className="setSecureMeTop px-3 py-1 text-white rounded-3 mb-3">Best Tool To Secure Your Loved ones</label>

                            <ul className="list-type-03 mb-4 list-unstyled">
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />


                                    Location Tracking: Tracks and records your movements only when activated, ensuring privacy and safety
                                </li>
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />


                                    Emergency Assistance: Helps law enforcement agencies locate you quickly in case of an emergency.
                                </li>
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />
                                    What-You-See Tool: Allows you to take pictures and record videos of important clues or evidence during emergencies
                                </li>

                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />

                                    Cloud Storage: Automatically stores your GPS/location data, images, and videos in the cloud for safekeeping.
                                </li>
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />


                                    Data Access: Provides access to stored data for you, your family members, or law enforcement agencies with your permission.
                                </li>
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />

                                    Enhanced Security: Keeps your data safe by not storing it on your phone, reducing the risk of loss or tampering.
                                </li>
                                <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />


                                    User Control: Empowers you to activate and deactivate tracking as needed, giving you control over your privacy.
                                </li>
                            </ul>
                            {/* <a href="/intellems-features" className="btn btn-primary">Learn More</a> */}
                            {/*<div className="pt-3 d-flex">


                  <NavLink className="btn btn-success bg-success-gradient-dark mb-0 btn-xl text-nowrap" to="/create-account">
                    <i className="fab fa-ubuntu mr-3" /> Create Account
                  </NavLink>
                </div>*/}

                            {/*<div className="createBtn">

                  <NavLink className="btn btn-success bg-success-gradient-dark mb-0 btn-xl text-nowrap" to="/create-account">
                    <i className="fab fa-ubuntu mr-3" /> Create Account
                  </NavLink>
                </div>*/}
                        </div>
                        <div className="col-md-6 my-1 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                            <img className="sm-img-120 tek-Images" src="/img/ims-images/innerPage.png" title="" alt="" />
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
})

export default AboutSecureMe
import MetaDataDecorator from "../utilities/MetaDataDecorator"
import Footer from "./layouts/Footer"
import Header from "./layouts/Header"
import AboutSecureMe from "./SecureMe/aboutSecureMe"
import SecureGetStarted from "./SecureMe/secureMeGetStarted"
import SecureMeTop from "./SecureMe/secureMeTop"


const SecureMe = (()=>{


return(



    <>
  <div className="wrapper">
       <Header headerStyle="navbar navbar-expand-lg"  cssStyle="nav-link text-dark" logoImage="/img/ims-images/IMS-Bluey.png"
                   logoImageDark="/img/ims-images/IMS-Bluey.png"  logoImageWhite="/img/ims-images/IMS-Bluey.png"
                   showProduct={false}/>
                   <MetaDataDecorator description="Home" title="secureMe | Home" />
                   <SecureMeTop/>
                   <AboutSecureMe/>
                   <SecureGetStarted/>

        
              <Footer footerBg="bg-dark footer" logoImage="/img/ims-images/intelli-log.png" /> 
        
        </div>   
    
    
    </>
)



})
export default SecureMe
import "./secureme.css"

const SecureMeTop = (()=>{

    return(


        <>
        
        <section id="home" className="bg-dark-gradient overflow-hidden ">
         
         <div className="container position-relative tecMoreOGAMoved">
           <div className="row min-vh-100 align-items-center ">
             <div className="col-md-7 wow fadeInUp fdOgaSave" data-wow-duration="0.5s">
               <img className="sm-img-120 yepImagesIm" src="/img/ims-images/SECUREMEMain.png" title="" alt="" />
               <div className="pt-3 d-flex downloadBtn">
                 <a className="me-3 downloadLink" target="blank" href="https://play.google.com/store/apps/details?id=com.intellims.secureme&pcampaignid=web_share">
                    <img src="/img/effect/playstore.png" title="" alt="" /></a>
                 <a href="https://apps.apple.com/ng/app/secureme/id6474111054" target="blank">
                 <img  src="/img/effect/apple-store.png" title="" alt="" /></a>
               </div>
             </div>
             <div className="col-md-5 pe-xl-10 wow fadeInUp" data-wow-duration="0.5s">
               <label className="setSecureMeTop px-3 py-1 rounded-3 mb-3">SecureMe offers you the best</label>
             
               <p className="lead">
               The security & safety of our loved ones and employees 
               is the responsibility of all of us.
               SecureMe was built to help keep us all safer by tracking
                 and recording our movement only
                 when activated by the individual.
                A powerful tool that can help law enforcement agencies
                 get to your location on time to provide help.
               </p>
              <p className="lead">
               Keep additional evidence to help your family and law 
               enforcement agencies in the case of emergency, 
               by using the What-You-See tool to take pictures 
               and record videos of important clues.
                </p>
            </div>
           </div>
         </div>
       </section>     
        
        </>
    )
})
export default SecureMeTop

import "./secureme.css";

const SecureGetStarted = (() => {


    return (

        <>
            <div className="responsive-container-block bigContainer">
                <div className="responsive-container-block Container bottomContainer">
                    <div className="ultimateImg">
                        <img className="mainImg"  src="/img/ims-images/HOMFAM-IPAD.png" />
                        <div className="purpleBox">
                            <p className="purpleText">
                                The security & safety of our loved ones and employees is our responsibility.
                            </p>
                            <img className="stars" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/mp5.svg" />
                        </div>
                    </div>
                    <div className="allText bottomText">
                        <p className="text-blk headingText">
                            Let's Get Started
                        </p>
                        <p className="text-blk subHeadingText">
                            SecureMe: A safety app that tracks and records your location and movements, storing data in the cloud for emergency use, and helps law enforcement with real-time evidence.
                        </p>
                        <ul className="list-type-03 mb-4 list-unstyled">
                            <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />

                                Download and Install: Download the SecureMe app from your mobile app store and install it on your device.
                            </li>
                            <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />


                                Create an Account: Sign up for an account by providing your personal details and setting up your profile.
                            </li>
                            <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />

                                Activate Tracking: Enable the tracking feature when you need it by activating it within the app. This will start recording your movement and location.
                            </li>

                            <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />


                                Use the What-You-See Tool: In case of an emergency, use the What-You-See tool to take pictures and record videos of important clues or evidence.
                            </li>
                            <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />


                                Cloud Storage: SecureMe will automatically store your GPS/location data, images, and videos in the cloud for safekeeping.
                            </li>
                            <li className="d-flex py-1"><i className="bi bi-check-circle-fill  me-2" />


                                Access and Share Data: You, your family members, or law enforcement agencies can access the stored data with your expressed permission, ensuring it is available when needed.
                            </li>

                        </ul>
                        <div className="d-flex downloadBtn2">

                                <a className="me-3" target="blank" href="https://play.google.com/store/apps/details?id=com.intellims.ogaapp&pcampaignid=web_share"><img src="/img/effect/playstore.png" title="" alt="" /></a>
                                <a href="https://apps.apple.com/ng/app/oga-app/id6472498562" target="blank"><img src="/img/effect/apple-store.png" title="" alt="" /></a>
                            </div>
                        {/* <a className="explore">
        View Services
      </a> */}
                    </div>
                </div>
            </div>

        </>
    )
})
export default SecureGetStarted
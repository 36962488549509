import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './layout.css'


const Header = ({ headerStyle, cssStyle, logoImageDark, logoImageWhite, showProduct, featureShow }) => {
  // console.log(window.location.pathname);
  const location = window.location.pathname;
  /*const hideToggleBar = () => {
    document.getElementById('navbarSupportedContent').style.display = 'none';
    // document.getElementById('navbarSupportedContent').style.display = 'show';
  }*/

  function scrollToProductDiv() {
    const divElement = document.getElementById('products');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }

  function scrollToPricingDiv() {
    const divElement = document.getElementById('pricing');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }
  function scrollToFeaturesDiv() {
    const divElement = document.getElementById('features');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }
  function scrollToAboutDiv() {
    const divElement = document.getElementById('about-us');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }
  function scrollToServiceDiv() {
    const divElement = document.getElementById('services');
    divElement.scrollIntoView({ behavior: 'smooth' });
    // hideToggleBar();
  }
  function scrollToMediaDiv() {
    const divElement = document.getElementById('media-section');
    divElement.scrollIntoView({ behavior: 'smooth' });
    // hideToggleBar();
  }




  return (
    <>
      <header className="header-main header-dark fixed-top">
        <nav className={headerStyle}>
          <div className="container">
            <a className="navbar-brand">
              <NavLink to="/">
                <img className="logo-dark" src={logoImageDark} title="" alt="" />
              </NavLink>
              <NavLink to="/">
                <img className="logo-light" src={logoImageWhite} title="" alt="" />
              </NavLink>
            </a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav m-auto navbar-navMOb">
                <li className="nav-item">
                  <NavLink to="/" className={cssStyle}>
                    Home
                  </NavLink>
                </li>

                {showProduct && (
                  <>
                    <li className="nav-item">
                      <NavLink to="/#products" className={cssStyle} onClick={scrollToProductDiv}>
                        Products
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/#" className={cssStyle} onClick={scrollToServiceDiv}>
                        Services
                      </NavLink>
                    </li>
                  </>
                )}

                <li className="nav-item">
                  {location !== '/secure-me' && location !== '/oga-app' && (
                    <NavLink to={location === '/contact-us' ? '/' : `${location}/#media-section`} className={cssStyle} onClick={scrollToMediaDiv}>
                      Media
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  <NavLink to={location === '/' ? `${location}/#about-us` : '/about-us'} className={cssStyle} onClick={scrollToAboutDiv}>
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact-us" className={cssStyle}>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>

            <div id="createAccountBtn" className="nav flex-column flex-lg-row d-none d-lg-flex">
              <ul className="navbar-nav ms-auto align-items-center">
                {location === '/tiptopgo-app' && (
                  <li className="nav-item">
                    <a href="https://www.tiptopgo.com" target="_blank" className="btn btn-md mb-0 ms-2 text-nowrap rounded text-white" style={{ backgroundColor: '#CCD12C' }}>
                      Book Now
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

Header.propTypes = {
  headerStyle: PropTypes.string,
  cssStyle: PropTypes.string,
  logoImage: PropTypes.string,
  headerName: PropTypes.string,
  showProduct: PropTypes.bool,
}

export default Header;
